import React, { ReactNode, useEffect, useRef, useState } from "react";

import { classNames } from "../../utils";

type Props<T extends { id: string }> = {
  items: T[];
  itemRenderer: (item: T) => ReactNode;
  className?: string;
};

export function FadeCarousel<T extends { id: string }>({
  items,
  itemRenderer,
  className,
}: Props<T>) {
  const itemMap = items.map((item, index) => {
    return { item, index };
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const timeoutRef = useRef<any>(null);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(
      () => setActiveIndex((prev) => (prev + 1 >= items.length ? 0 : prev + 1)),
      5000
    );

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [activeIndex]);

  return (
    <div className={className}>
      {itemMap.map((item) => (
        <div
          key={item.item.id}
          className={classNames(
            "transition duration-[2000ms] absolute top-0 left-0 h-full w-full z-10 ",
            item.index === activeIndex ? "opacity-100" : "opacity-0"
          )}
        >
          {itemRenderer(item.item)}
        </div>
      ))}
    </div>
  );
}
