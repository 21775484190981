import { Dialog, Popover, Transition } from "@headlessui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { Fragment, ReactNode, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Image, OfferItem, OfferStatus as OfferStatusType } from "../../types";
import { classNames } from "../../utils";

const pathToId: {
  [id: number]: (
    onClick: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void
  ) => ReactNode;
} = {
  1: (onClick: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void) => (
    <path
      d="m287.85 286.42 206.83-228.88v-11.748l-155.52-27.937-144.26 162.32v8.911l40.926 43.856-6.3862 7.5783 24.182 26.481 8.8555-4.598z"
      className="text-black opacity-0 hover:opacity-50 transition duration-500 relative cursor-pointer"
      fill="currentColor"
      onClick={(e) => onClick(e)}
    />
  ),
  2: (onClick: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void) => (
    <path
      d="m194.89 189.09-15.698-16.685-10.303 3.6614-30.398-32.272 8.6852-3.8317-68.8-72.547-74.761-38.317v-9.5367l11.751-14.305 6.1307-5.1089 136.07 4.0872 114.27-2.2139v2.7248l67.317 13.107-144.26 162.32z"
      className="text-black opacity-0 hover:opacity-50 transition duration-500 relative cursor-pointer"
      fill="currentColor"
      onClick={(e) => onClick(e)}
    />
  ),
  3: (onClick: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void) => (
    <path
      d="m195.62 372.73-79.958-14.932-115-59.728 0.49138-9.9463 115.29-132.66 87.845 94.802 13.607 9.5131 18.183 24.927-1.2042 6.623 6.3822 7.8272 3.0105 8.7906 1.445 11.079-2.89 7.9477-8.1885 8.6702z"
      className="text-black opacity-0 hover:opacity-50 transition duration-500 relative cursor-pointer"
      fill="currentColor"
      onClick={(e) => onClick(e)}
    />
  ),
  4: (onClick: (e: React.MouseEvent<SVGPathElement, MouseEvent>) => void) => (
    <path
      d="m1.1491 288.12 115.29-132.66-6.9822-11.41-23.671-20.606-5.1089-0.34059-96.9-72.717-9.9744 14.672-9.6335 14.33-10.356 15.113-20.652 30.948-11.5 17.581-12.704 14.751-18.844 15.391z"
      className="text-black opacity-0 hover:opacity-50 transition duration-500 relative cursor-pointer"
      fill="currentColor"
      onClick={(e) => onClick(e)}
    />
  ),
};

type Props = {
  title: string;
  description: string;
  image: Image;
  items: OfferItem[];
  onOfferClick: (house: OfferItem) => void;
};

export const SelectionSection = ({
  title,
  description,
  items,
  onOfferClick,
  image,
}: Props) => {
  const [offers, setOffers] = useState(
    items.map((offer) => ({
      ...offer,
      isOpen: false,
      path: pathToId[offer.id],
    }))
  );
  return (
    <section className="relative" id="vyber-domu">
      <header className="text-center mb-8 pt-8 max-w-5xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-1 md:mb-2 font-semibold font-display">
          {title}
        </h2>
        <hr className="w-32 border-black mb-4 mx-auto" />
        <p className="font-light lg:text-lg text-gray-600 mb-2 max-w-5xl mx-auto">
          {description}
        </p>
      </header>
      <div className="relative border-t ">
        <svg
          className="w-full h-full absolute left-0 top-0 z-10"
          version="1.1"
          viewBox="0 0 762 427.83"
          xmlns="http://www.w3.org/2000/svg"
          onClick={() =>
            setOffers([...offers.map((offer) => ({ ...offer, isOpen: false }))])
          }
        >
          <g transform="translate(213.09 42.596)">
            {offers.map((offer, i) => (
              <g key={offer.title}>
                {offer.path((e) => {
                  setOffers([
                    ...offers
                      .slice(0, i)
                      .map((offer) => ({ ...offer, isOpen: false })),
                    {
                      ...offers[i],
                      isOpen: !offers[i].isOpen,
                    },
                    ...offers
                      .slice(i + 1)
                      .map((offer) => ({ ...offer, isOpen: false })),
                  ]);

                  e.stopPropagation();
                })}
              </g>
            ))}
          </g>
        </svg>
        {offers.map((offer, i) => (
          <OfferPopover
            key={offer.title}
            {...offer}
            setIsOpen={(isOpen) => {
              setOffers([
                ...offers.slice(0, i),
                {
                  ...offers[i],
                  isOpen: isOpen,
                },
                ...offers.slice(i + 1),
              ]);
            }}
            onOfferClick={() => onOfferClick(offer)}
          />
        ))}

        <Fade delay={100} className="w-full h-full" triggerOnce>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            className="w-full"
            alt=""
          />
        </Fade>
      </div>
      <div className="relative bg-black">
        <div className="px-4 mx-auto py-8 relative">
          <div className="border border-gray-700 overflow-auto shadow-lg bg-gray-900">
            <table className="w-full ">
              <thead className="bg-gray-800 border-b-2 border-gray-700 text-left font-semibold text-sm sm:text-base lg:text-lg text-gray-300">
                <tr className="">
                  <th className="px-4 sm:px-6 py-2 sm:py-2 whitespace-nowrap">
                    Název
                  </th>
                  <th className="px-4 sm:px-6 py-2 sm:py-2 whitespace-nowrap">
                    Dispozice
                  </th>
                  <th className="px-4 sm:px-6 py-2 sm:py-2 whitespace-nowrap">
                    Plocha domu
                  </th>
                  <th className="px-4 sm:px-6 py-2 sm:py-2 whitespace-nowrap">
                    Plocha pozemku
                  </th>
                  <th className="px-4 sm:px-6 py-2 sm:py-2 whitespace-nowrap">
                    Cena
                  </th>
                  <th className="px-4 sm:px-6 py-2 sm:py-2 whitespace-nowrap">
                    Stav
                  </th>
                  <th className="px-4 sm:px-6 py-2 sm:py-2 whitespace-nowrap"></th>
                </tr>
              </thead>
              <tbody className="text-gray-200 text-sm sm:text-base">
                {items.map((offer) => (
                  <tr key={offer.id}>
                    <td className="px-4 sm:px-6 py-1 sm:py-2 whitespace-nowrap">
                      {offer.title}
                    </td>
                    <td className="px-4 sm:px-6 py-1 sm:py-2 whitespace-nowrap">
                      {offer.dispositions}
                    </td>
                    <td className="px-4 sm:px-6 py-1 sm:py-2 whitespace-nowrap">
                      {offer.houseArea} m<sup>2</sup>
                    </td>
                    <td className="px-4 sm:px-6 py-1 sm:py-2 whitespace-nowrap">
                      {offer.landArea} m<sup>2</sup>
                    </td>
                    <td className="px-4 sm:px-6 py-1 sm:py-2 whitespace-nowrap">
                      {offer.price === 0 ? (
                        <div className="ml-10"> - </div>
                      ) : (
                        <>{offer.price.toLocaleString("cs")} Kč</>
                      )}
                    </td>
                    <td className="px-4 sm:px-6 py-1 sm:py-2 whitespace-nowrap">
                      <span
                        className={`font-medium ${
                          offer.status === "Prodáno"
                            ? "text-red-600"
                            : offer.status === "Rezervováno"
                            ? "text-yellow-600"
                            : "text-green-600"
                        }`}
                      >
                        {offer.status}
                      </span>
                    </td>
                    <td className="px-4 sm:px-6 py-1 sm:py-2 whitespace-nowrap">
                      {offer.status === "K Dispozici" ? (
                        <button
                          className="inline-flex rounded gap-4 px-4 py-2 text-sm items-center border-2 border-white bg-white text-black shadow shadow-white/25 transition duration-300 hover:shadow-lg hover:shadow-white/25 focus:shadow-lg focus:shadow-white/25 focus:outline-none group"
                          onClick={() => {
                            onOfferClick(offer);
                          }}
                        >
                          <span className="font-medium tracking-wide">
                            Mám zájem
                          </span>
                        </button>
                      ) : (
                        <div style={{ height: 44 }}></div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

type OfferPopoverProps = OfferItem & {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onOfferClick: () => void;
};

export const OfferPopover = ({
  title,
  dispositions,
  houseArea,
  landArea,
  price,
  status,
  left,
  top,
  isOpen,
  setIsOpen,
  onOfferClick,
}: OfferPopoverProps) => {
  return (
    <div
      className="absolute z-20 pointer-events-none"
      style={{
        left: left,
        top: top,
      }}
    >
      <Popover as={Fragment}>
        <div className={"relative"}>
          <div className="bg-white animate-ping h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 shadow-xl rounded-full absolute pointer-events-none"></div>
          <div className="bg-white h-4 w-4 sm:h-6 sm:w-6 md:h-8 md:w-8 shadow-xl rounded-full"></div>
          <span className="sr-only">{title}</span>
        </div>

        <Transition
          enter="transition duration-300 ease-out"
          enterFrom="transform opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-300 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform opacity-0"
          as={Fragment}
          show={isOpen && window.innerWidth > 768}
        >
          <Popover.Panel className="hidden md:block absolute left-1/2 z-10 mt-2 whitespace-nowrap bg-white rounded shadow-xl shadow-black/50 -translate-x-1/2 -translate-y-1/2 text-center  pointer-events-auto">
            <div className="font-bold text-gray-900 text-xl mb-2 border-b pt-3 pb-2 px-8 bg-gray-100 rounded-t">
              {title}
            </div>
            <dl className=" py-4 px-8">
              <div className="mb-2">
                <dt className="text-gray-600 text-sm">Interiér</dt>
                <dd className="text-gray-900 font-semibold">
                  {dispositions} / {houseArea} m2
                </dd>
              </div>
              <div className="mb-2">
                <dt className="text-gray-600 text-sm">Pozemek</dt>
                <dd className="text-gray-900 font-semibold">{landArea} m2</dd>
              </div>
              {status === "K Dispozici" && (
                <div className="mb-2">
                  <dt>
                    <span className="text-gray-600 text-sm">Cena</span>
                  </dt>
                  <dd className="text-gray-900 font-semibold">
                    {price.toLocaleString("cs")} Kč
                  </dd>
                </div>
              )}
              <div>
                <dt>
                  <span className="sr-only">Dostupnost</span>
                </dt>
                <OfferStatus status={status} />
              </div>
            </dl>
            {status === "K Dispozici" && (
              <div className="px-8 pb-4">
                <button
                  className="inline-flex gap-4 px-4 py-2 lg:px-6 lg:py-3 text-sm lg:text-base items-center border-2 border-black bg-black text-white shadow transition duration-300 hover:shadow-xl focus:shadow-xl focus:outline-none group"
                  onClick={() => {
                    onOfferClick(), setIsOpen(false);
                  }}
                >
                  <span className="font-medium tracking-wide">Mám zájem</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 lg:h-6 lg:w-6 group-hover:scale-110 group-focus:scale-110 transition duration-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>

      <Transition
        show={isOpen && window.innerWidth < 768}
        enter="transition duration-300 ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform  opacity-100"
        leave="transition duration-300 ease-out"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
        as={Fragment}
      >
        <Dialog
          className="fixed inset-0 z-50 overflow-y-auto flex justify-center items-center"
          onClose={() => setIsOpen(false)}
        >
          <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-75"></Dialog.Overlay>
          <div className="bg-white rounded text-center relative z-20">
            <Dialog.Title className="font-bold text-gray-900 text-2xl mb-2 border-b pt-3 pb-2 px-12 bg-gray-100 rounded-t">
              {title}
            </Dialog.Title>
            <Dialog.Description as="dl" className=" py-4 px-12">
              <div className="mb-2">
                <dt className="text-gray-600 text-sm">Interiér</dt>
                <dd className="text-gray-900 font-semibold">
                  {dispositions} / {houseArea} m2
                </dd>
              </div>
              <div className="mb-2">
                <dt className="text-gray-600 text-sm">Pozemek</dt>
                <dd className="text-gray-900 font-semibold">{landArea} m2</dd>
              </div>
              {status === "K Dispozici" && (
                <div className="mb-2">
                  <dt>
                    <span className="text-gray-600 text-sm">Cena</span>
                  </dt>
                  <dd className="text-gray-900 font-semibold">
                    {price.toLocaleString("cs")} Kč
                  </dd>
                </div>
              )}
              <div>
                <dt>
                  <span className="sr-only">Dostupnost</span>
                </dt>
                <OfferStatus status={status} />
              </div>
            </Dialog.Description>
            <div className="px-12 pb-4 flex flex-col gap-2">
              {status === "K Dispozici" && (
                <button
                  className="inline-flex gap-4 px-4 py-2 lg:px-6 lg:py-3 text-sm lg:text-base items-center border-2 border-black bg-black text-white shadow transition duration-300 hover:shadow-xl focus:shadow-xl focus:outline-none group"
                  onClick={() => {
                    onOfferClick(), setIsOpen(false);
                  }}
                >
                  <span className="font-medium tracking-wide">Mám zájem</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 lg:h-6 lg:w-6 group-hover:scale-110 group-focus:scale-110 transition duration-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
              <button
                className="inline-flex gap-4 px-4 py-2 text-xs lg:text-base items-center justify-center border border-black duration-300 hover:bg-black hover:text-white focus:bg-black focus:text-white"
                onClick={() => setIsOpen(false)}
              >
                <span className="font-medium tracking-wide">Zavřít</span>
              </button>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

const OfferStatus = ({ status }: { status: OfferStatusType }) => (
  <dd
    className={classNames(
      "py-1 px-3 text-sm rounded-xl w-fit mx-auto",
      status === "K Dispozici" && "bg-green-600 text-white",
      status === "Rezervováno" && "bg-orange-600 text-white",
      status === "Prodáno" && "bg-red-600 text-white"
    )}
  >
    {status}
  </dd>
);
