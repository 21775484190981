import { Menu, Transition } from "@headlessui/react";
import { Link } from "gatsby";
import React, { createRef, Fragment, useEffect, useState } from "react";
import { Logo } from "../atoms";
import { classNames } from "../../utils";

export const Navbar = () => {
  const [appear, setAppear] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [scrolledPosition, setScrolledPosition] = useState(false);
  const [scrolledReveal, setScrolledReveal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    setAppear(true);

    window.addEventListener("scroll", () => {
      setScrolled(window.scrollY > 160);
      setScrolledPosition(window.scrollY > 320);
      setScrolledReveal(window.scrollY > window.innerHeight);
    });
  }, []);

  return (
    <Transition
      appear
      show={appear}
      enterFrom="opacity-0"
      enter="transition ease-in duration-[1000ms] delay-[2000ms]"
      enterTo="opacity-100"
      className={classNames(
        "left-0 top-0 right-0 z-50 px-4 sm:px-8 py-3 sm:py-5 ",
        menuOpen
          ? "fixed bg-black transition duration-500 "
          : scrolled
          ? scrolledPosition
            ? scrolledReveal
              ? "fixed  transition duration-500 bg-black"
              : "fixed -translate-y-full transition duration-500 bg-black"
            : "absolute -translate-y-full bg-black"
          : "absolute bg-black bg-opacity-25 transition duration-500"
      )}
      as="header"
    >
      <div className="flex items-center justify-between text-gray-100">
        <Link
          to="/"
          className="font-extralight tracking-wide hover:text-white focus:text-white focus:outline-none transition duration-300 flex gap-3 sm:gap-6 items-center"
          title="Jizerské Roubenky"
        >
          <Logo className="h-8 sm:h-10 -my-2" />
          <span className="text-lg sm:text-xl -my-4">Jizerské Roubenky</span>
        </Link>
        <div className="hidden lg:flex gap-8 font-light">
          <Link
            to="/#uvod"
            className="hover:text-white focus:text-white border-b border-transparent focus:border-white hover:border-white focus:outline-none transition duration-300"
          >
            Úvod
          </Link>
          <Link
            to="/#charakteristika"
            className="hover:text-white focus:text-white border-b border-transparent focus:border-white hover:border-white focus:outline-none transition duration-300"
          >
            Charakteristika
          </Link>
          <Link
            to="/#investor"
            className="hover:text-white focus:text-white border-b border-transparent focus:border-white hover:border-white focus:outline-none transition duration-300"
          >
            Investor
          </Link>
          <Link
            to="/#vyber-domu"
            className="hover:text-white focus:text-white border-b border-transparent focus:border-white hover:border-white focus:outline-none transition duration-300"
          >
            Výběr domu
          </Link>
          <Link
            to="/#kontakt"
            className="hover:text-white focus:text-white border-b border-transparent focus:border-white hover:border-white focus:outline-none transition duration-300"
          >
            Kontakt
          </Link>
          <Link
            to="/#fotogalerie"
            className="hover:text-white focus:text-white border-b border-transparent focus:border-white hover:border-white focus:outline-none transition duration-300"
          >
            Fotogalerie
          </Link>
          <Link
            to="/#dokumenty"
            className="hover:text-white focus:text-white border-b border-transparent focus:border-white hover:border-white focus:outline-none transition duration-300"
          >
            Dokumenty
          </Link>
        </div>
        <div className="lg:hidden">
          <Menu>
            {({ open }) => {
              setMenuOpen(open);

              return (
                <>
                  <Menu.Button title="Navigace">
                    <svg
                      className="h-6 w-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 18H12V16H21V18ZM21 13H3V11H21V13ZM21 8H3V6H21V8Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-300"
                    enterFrom="transform opacity-50 translate-x-full"
                    enterTo="transform opacity-100"
                    leave="transition ease-in duration-300"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-50 translate-x-full"
                  >
                    <Menu.Items className="absolute top-full left-0 right-0 bg-black text-center flex flex-col shadow-xl shadow-black/40 border-t border-gray-900">
                      <Menu.Item>
                        <Link
                          to="/#uvod"
                          className="hover:text-white focus:text-white focus:outline-none transition duration-300 py-4 sm:py-6"
                        >
                          Úvod
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/#charakteristika"
                          className="hover:text-white focus:text-white focus:outline-none transition duration-300 py-4 sm:py-6"
                        >
                          Charakteristika
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/#investor"
                          className="hover:text-white focus:text-white focus:outline-none transition duration-300 py-4 sm:py-6"
                        >
                          Investor
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/#kontakt"
                          className="hover:text-white focus:text-white focus:outline-none transition duration-300 py-4 sm:py-6"
                        >
                          Kontakt
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/#fotogalerie"
                          className="hover:text-white focus:text-white focus:outline-none transition duration-300 py-4 sm:py-6"
                        >
                          Fotogalerie
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to="/#dokumenty"
                          className="hover:text-white focus:text-white focus:outline-none transition duration-300 py-4 sm:py-6"
                        >
                          Dokumenty
                        </Link>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              );
            }}
          </Menu>
        </div>
      </div>
    </Transition>
  );
};
