import { Dialog, Transition } from "@headlessui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { createRef, Fragment, useEffect, useState } from "react";
import { useSwipeable } from "react-swipeable";
import { Image } from "../../types";
import { classNames } from "../../utils";
import ReactDOM from "react-dom";
import { useHotkeys } from "react-hotkeys-hook";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  images: {
    image: Image;
    alt: string;
  }[];
};

function useDebounce<T>(
  initialValue: T,
  time: number
): [T, T, React.Dispatch<T>] {
  const [value, setValue] = useState<T>(initialValue);
  const [debouncedValue, setDebouncedValue] = useState<T>(initialValue);

  useEffect(() => {
    const debounce = setTimeout(() => {
      setDebouncedValue(value);
    }, time);
    return () => {
      clearTimeout(debounce);
    };
  }, [value, time]);

  return [debouncedValue, value, setValue];
}

export const Gallery = ({ title, images, isOpen, onClose }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [translate, setTranslate] = useState<number>();
  useHotkeys("esc", () => onClose());
  const innerCarouselRef = createRef<HTMLDivElement>();
  const contentCarouselRef = createRef<HTMLDivElement>();
  const [debouncedValue, value, setValue] = useDebounce<KeyboardEvent>(
    typeof window === "undefined"
      ? (undefined as unknown as KeyboardEvent) // To fix SSR build
      : new KeyboardEvent("", {}),
    250
  );
  const itemMap = images.map((item, index) => {
    return { item, index };
  });

  function keyDownListener(event: KeyboardEvent) {
    if (isOpen) setValue(event);
  }

  useEffect(() => {
    window.addEventListener("keydown", keyDownListener);

    return () => {
      window.removeEventListener("keydown", keyDownListener);
    };
  });

  useEffect(() => {
    debouncedValue.key === "ArrowDown" && onPreviousClick();
    debouncedValue.key === "ArrowLeft" && onPreviousClick();
    debouncedValue.key === "ArrowUp" && onNextClick();
    debouncedValue.key === "ArrowRight" && onNextClick();
  }, [debouncedValue]);

  useEffect(() => {
    if (
      innerCarouselRef.current &&
      innerCarouselRef.current.clientWidth > 0 &&
      activeIndex > -1
    )
      setTranslate(
        -(innerCarouselRef.current.clientWidth / images.length) * activeIndex +
          innerCarouselRef.current.clientWidth / 2 -
          innerCarouselRef.current.clientWidth / images.length
      );
  }, [activeIndex]);

  function onPreviousClick() {
    setActiveIndex(activeIndex === 0 ? images.length - 1 : activeIndex - 1);
  }

  function onNextClick() {
    setActiveIndex(activeIndex >= images.length - 1 ? 0 : activeIndex + 1);
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (_) => onNextClick(),
    onSwipedRight: (_) => onPreviousClick(),
    onSwipedDown: (_) => onClose(),
    onSwipedUp: (_) => onClose(),
    delta: {
      down: 200,
      up: 200,
      left: 50,
      right: 50,
    },
  });

  return typeof document === "undefined" ? (
    <></>
  ) : (
    ReactDOM.createPortal(
      <Transition show={isOpen} as={Fragment}>
        <dialog
          className="fixed inset-0 w-full z-50 overflow-y-auto bg-transparent m-0 p-0"
          open
        >
          <div className="h-screen overflow-hidden">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-90" />
            </Transition.Child>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative z-10 h-screen flex flex-col">
                {/*  Top action bar */}
                <div className="px-3 lg:px-6 py-4 flex gap-2 justify-between text-gray-300 text-sm lg:text-base">
                  <div className="">
                    {activeIndex + 1} / {images.length}
                  </div>
                  <h3 className="">{title}</h3>
                  <button
                    className="hover:text-white focus:text-white focus:outline-none transition duration-300"
                    onClick={() => onClose()}
                  >
                    <svg
                      className="h-5 w-5 lg:h-6 lg:w-6"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </button>
                </div>

                {/* Main content */}
                <div
                  {...swipeHandlers}
                  className="py-8 grow overflow-hidden"
                  onClick={() => onClose()}
                  ref={contentCarouselRef}
                >
                  <div
                    className="whitespace-nowrap transition duration-300  py-8 grow w-full h-full"
                    style={{
                      transform: `translateX(${-activeIndex * 100}%)`,
                    }}
                    onClick={() => onClose()}
                  >
                    {itemMap.map((item) => (
                      <div
                        key={item.item.alt}
                        className={
                          "inline-flex items-center justify-center w-full h-full"
                        }
                      >
                        <GatsbyImage
                          alt={item.item.alt}
                          key={item.item.alt}
                          image={
                            item.item.image.childImageSharp.gatsbyImageData
                          }
                          className="h-fit mx-auto"
                          onClick={(e) => e.stopPropagation()}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Bottom navigation preview */}
                <div className="px-3 lg:px-6 py-2 lg:py-4 overflow-hidden">
                  <div
                    className={classNames(
                      "whitespace-nowrap flex justify-center space-x-4 transition duration-300 w-fit mx-auto"
                    )}
                    ref={innerCarouselRef}
                    style={{
                      transform: `translateX(${translate}px)`,
                    }}
                  >
                    {images.map((image, index) => (
                      <button
                        key={image.alt}
                        className={classNames(
                          "hover:opacity-100 focus:opacity-100 focus:outline-none transition duration-300 border-2",
                          activeIndex == index
                            ? "border-gray-200 opacity-90"
                            : "border-transparent opacity-75"
                        )}
                        onClick={() => setActiveIndex(index)}
                      >
                        <div>
                          <GatsbyImage
                            alt={image.alt}
                            key={image.alt}
                            image={image.image.childImageSharp.gatsbyImageData}
                            className="h-14 lg:h-24 aspect-[4/3] object-cover"
                          />
                        </div>
                      </button>
                    ))}
                  </div>
                </div>

                {/* Arrow buttons */}
                <button
                  className="absolute p-1 bg-black bg-opacity-50 z-20 text-gray-200 left-4 top-1/2 text-gray-200 transform -translate-y-1/2 hover:text-white focus:outline-none focus:text-white"
                  onClick={() => onPreviousClick()}
                >
                  <svg
                    className="h-6 w-6 lg:h-8 lg:w-8"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.535 3.515L7.05005 12L15.535 20.485L16.95 19.071L9.87805 12L16.95 4.929L15.535 3.515Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
                <button
                  className="absolute p-1 bg-black bg-opacity-50 z-20 text-gray-200 right-4 top-1/2 text-gray-200 transform -translate-y-1/2 hover:text-white focus:outline-none focus:text-white"
                  onClick={() => onNextClick()}
                >
                  <svg
                    className="h-6 w-6 lg:h-8 lg:w-8"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.46495 20.485L16.95 12L8.46495 3.515L7.04995 4.929L14.122 12L7.04995 19.071L8.46495 20.485Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </button>
              </div>
            </Transition.Child>
          </div>
        </dialog>
      </Transition>,
      document?.getElementById("preview-pane")?.contentWindow.document.body ??
        document?.body
    )
  );
};
