import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Image } from "../../types";
import { Gallery } from "../molecules/Gallery";

type Gallery = {
  title: string;
  thumbnail: Image;
  images: {
    image: Image;
    alt: string;
  }[];
};

type Props = {
  title: string;
  description: string;
  galleries: {
    title: string;
    thumbnail: Image;
    images: {
      image: Image;
      alt: string;
    }[];
  }[];
};

export const GallerySection = ({ title, description, galleries }: Props) => {
  const GallerySectionItem = (gallery: Gallery) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <li className="relative z-10 shadow-lg border">
        <div className="relative">
          <Gallery
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            {...gallery}
          />
          <Fade delay={100} triggerOnce>
            <GatsbyImage
              image={gallery.thumbnail.childImageSharp.gatsbyImageData}
              alt=""
              className="h-full"
              imgClassName="h-full object-cover"
            />
          </Fade>
          <button
            onClick={() => setIsOpen(true)}
            className="absolute left-0 top-4 right-0 bg-black bg-opacity-80 pl-6 py-4 pr-6 md:pr-36 text-gray-200 text w-fit lg:text-lg font-medium tracking-wide z-30 shadow-lg shadow-black/25 hover:text-white focus:outline-none transition duration-300 hover:bg-opacity-100 hover:shadow-xl hover:shadow-black/40 focus:bg-opacity-100 focus:text-white focus:shadow-xl focus:shadow-black/40 group"
          >
            <span className="mb-2 block">{gallery.title}</span>
            <span className="text-xs md:text-sm flex gap-2 block text-gray-300 group-hover:text-gray-100 group-focus:text-gray-100 transition duration-300">
              <span>Zobrazit</span>
              <svg
                className="h-4 w-4 md:h-5 md:w-5 text-gray-400 group-hover:text-gray-200 group-focus:text-gray-200 transition duration-300"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 18H20C21.1046 18 22 17.1046 22 16V4C22 2.89543 21.1046 2 20 2H8C6.89543 2 6 2.89543 6 4V16C6 17.1046 6.89543 18 8 18ZM8 16V4H20V16H8Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M2 8H4V20H16V22H4C2.89543 22 2 21.1046 2 20V8Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M10 14H19L15.25 8.55556L13 11.6667L12.25 10.8889L10 14Z"
                  fill="currentColor"
                ></path>
                <path
                  d="M10.75 8.16667C10.75 8.811 11.2537 9.33333 11.875 9.33333C12.4963 9.33333 13 8.811 13 8.16667C13 7.52233 12.4963 7 11.875 7C11.2537 7 10.75 7.52233 10.75 8.16667Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      </li>
    );
  };

  return (
    <section className="container px-4 mx-auto py-16" id="fotogalerie">
      <header className="max-w-7xl mx-auto text-center mb-24">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-1 md:mb-2 font-semibold font-display">
          {title}
        </h2>
        <hr className="w-32 border-black mb-4 mx-auto" />
        <p className="font-light lg:text-lg text-gray-600 mb-2 max-w-5xl mx-auto whitespace-pre-wrap">
          {description}
        </p>
      </header>
      <ul role="list" className="grid lg:grid-cols-2 gap-8 list-none">
        {galleries.map((gallery) => (
          <GallerySectionItem key={gallery.title} {...gallery} />
        ))}
      </ul>
    </section>
  );
};
