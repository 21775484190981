import React from "react";
import { Fade } from "react-awesome-reveal";

type Props = {
  title: string;
  src: string;
};

export const MapSection = ({ title, src }: Props) => (
  <section className="relative md:my-4 lg:my-8 xl:my-12">
    <Fade duration={1500} triggerOnce>
      <iframe
        src={src}
        height="300"
        allowFullScreen
        className="w-full grayscale relative z-10 shadow border-0 pointer-events-none"
        loading="lazy"
        title={title}
      />
    </Fade>
  </section>
);
