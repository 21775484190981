import React, { FormEvent, useRef, useState } from "react";
import { classNames } from "../../utils";

type Props = {
  title: string;
  description: string;
  person: string;
  tel: string;
  mail: string;
  adress: string;
  mapLink: string;
  items: string[];
  house: string;
  onHouseChange: (value: string) => void;
};

export const ContactSection = ({
  title,
  description,
  person,
  tel,
  mail,
  adress,
  mapLink,
  items,
  house,
  onHouseChange,
}: Props) => {
  const [name, setName] = useState("");
  const [nameValid, setNameValid] = useState<boolean>(false);
  const [nameTouched, setNameTouched] = useState<boolean>(false);

  const [email, setEmail] = useState("");
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [emailMessage, setEmailMessage] = useState("");
  const [emailTouched, setEmailTouched] = useState<boolean>(false);

  const [phone, setPhone] = useState("");
  const [phoneValid, setPhoneValid] = useState<boolean>(false);
  const [phoneTouched, setPhoneTouched] = useState<boolean>(false);

  const [message, setMessage] = useState("");
  const [messageValid, setMessageValid] = useState<boolean>(false);
  const [messageTouched, setMessageTouched] = useState<boolean>(false);

  const [houseValid, setHouseValid] = useState<boolean>(false);
  const [houseTouched, setHouseTouched] = useState<boolean>(false);

  const [result, setResult] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSending, setIsSending] = useState(false);

  function onNameChangeHandler(value: string) {
    const isValid = value !== "";

    setName(value);
    setNameTouched(true);
    setNameValid(isValid);
  }

  function onEmailChangeHandler(value: string) {
    const isValid = value !== "";
    const isValidEmail = /^\S+@\S+$/.test(value);

    const validationMessage = isValid
      ? isValidEmail
        ? ""
        : "Zadejte platnou emailovou adresu"
      : "Email musí být vyplněn";

    setEmail(value);
    setEmailTouched(true);
    setEmailMessage(validationMessage);
    setEmailValid(isValid && isValidEmail);
  }

  function onPhoneChangeHandler(value: string) {
    setPhone(value);
    setPhoneValid(true);
    setPhoneTouched(true);
  }

  function onMessageChangeHandler(value: string) {
    setMessage(value);
    setMessageValid(true);
    setMessageTouched(true);
  }

  function onHouseChangeHandler(value: string) {
    onHouseChange(value);
    setHouseValid(true);
    setHouseTouched(true);
  }

  function onSubmitHandler(e: FormEvent) {
    e.preventDefault();
    onEmailChangeHandler(email);

    if (!emailValid) {
      document
        .getElementById("email")
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById("email")?.focus({ preventScroll: true });
      return;
    }

    onNameChangeHandler(name);
    if (!nameValid) {
      document
        .getElementById("name")
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document.getElementById("name")?.focus({ preventScroll: true });
      return;
    }

    setIsSending(true);
    setIsSuccess(false);
    setResult("");
    
    sendTestMessage();
    // sendMessage();
  }

  function sendTestMessage() {
    setTimeout(() => {
      setResult(
        "Vaše zpráva by byla úspěšně odeslána - v ukázkovém řežimu však zprávy nechodí."
      );
      setIsSuccess(true);
      setIsSending(false);
    }, 2000);
  }

  function sendMessage() {
    fetch(
      "https://prene-horske-chalupy.azurewebsites.net/api/ContactFormMessage",
      {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: email,
          phone: phone,
          house: house,
          message: message,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setResult("Vaše zpráva byla úspěšně odeslána");
          setIsSuccess(true);
        } else {
          setResult(
            "Při odesílání zprávy nastala chyba, zkuste to prosím znovu nebo nás kontaktujte jiným způsobem"
          );
          setIsSuccess(false);
        }

        setIsSending(false);
      })
      .catch((_) => {
        setResult(
          "Při odesílání zprávy nastala chyba, zkuste to prosím znovu nebo nás kontaktujte jiným způsobem"
        );
        setIsSuccess(false);
        setIsSending(false);
      });
  }

  return (
    <section
      className="pb-24 pt-12 bg-black shadow-2xl shadow-gray-800/30 relative"
      id="kontakt"
    >
      <div className="container px-4 mx-auto grid lg:grid-cols-4 gap-12 relative z-20">
        <div className="col-span-2 pb-8 lg:pb-0 border-b-2 lg:border-b-0 lg:border-r-2 border-gray-800">
          <header className="mb-12">
            <h2 className="text-5xl font-bold mb-6 font-display text-gray-100">
              {title}
            </h2>
            <p className="font-light text-gray-300 leading-relaxed whitespace-pre-wrap">
              {description}
            </p>
          </header>
          <address className="text-gray-200 text-lg not-italic">
            <div className="flex gap-4 items-center mb-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M10 6H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V8a2 2 0 00-2-2h-5m-4 0V5a2 2 0 114 0v1m-4 0a2 2 0 104 0m-5 8a2 2 0 100-4 2 2 0 000 4zm0 0c1.306 0 2.417.835 2.83 2M9 14a3.001 3.001 0 00-2.83 2M15 11h3m-3 4h2"
                />
              </svg>
              <span>{person}</span>
            </div>
            <a
              href={`tel:${tel}`}
              className="flex gap-4 items-center mb-6 hover:text-white focus:text-white focus:outline-none transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                />
              </svg>
              <span>{tel}</span>
            </a>
            <a
              href={`mailto:${mail}`}
              className="flex gap-4 items-center mb-12 hover:text-white focus:text-white focus:outline-none transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
              <span>{mail}</span>
            </a>
            <div>
              <div className="mb-3">{adress}</div>
              <div>
                <a
                  className="text-gray-300 underline decoration-1 underline-offset-2 hover:text-gray-100 focus:text-gray-100 focus:outline-none transition duration-300"
                  href={mapLink}
                  target="_blank"
                  rel="noopener noreferrer external"
                >
                  Zobrazit na mapě
                </a>
              </div>
            </div>
          </address>
        </div>
        <form
          onSubmit={onSubmitHandler}
          className="grid sm:grid-cols-2 col-span-2 gap-6"
        >
          <h3 className="sm:col-span-2 text-3xl font-semibold mb-2 font-display text-gray-100">
            Kontaktní formulář
          </h3>
          <div className="flex flex-col gap-1 items-stretch group">
            <label
              htmlFor="email"
              className="text-gray-300 text-sm group-focus-within:text-gray-100 transition duration-300"
            >
              Emailová adresa
            </label>
            <div className="relative">
              <input
                type="email"
                className="border-2 border-gray-400 bg-gray-700 py-3 h-12 pl-14 pr-4 rounded-sm text-gray-100 w-full text-sm focus:outline-none focus:border-gray-100 focus:shadow-lg focus:shadow-white/10 transition duration-300"
                name="email"
                id="email"
                onChange={(e) => onEmailChangeHandler(e.currentTarget.value)}
                value={email}
                aria-invalid={emailTouched && !emailValid}
              />

              <div
                className={classNames(
                  "absolute left-3 top-1/2 transform -translate-y-1/2 border-r-2   transition duration-300 pr-3",
                  emailTouched && !emailValid
                    ? "text-red-500 border-red-500"
                    : "border-gray-400 text-gray-300 group-focus-within:text-gray-100 group-focus-within:border-gray-100"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
              </div>
            </div>
            {emailTouched && !emailValid && (
              <p role="alert" className="text-red-500 text-sm font-extralight">
                {emailMessage}
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1 items-stretch group">
            <label
              htmlFor="nameSurname"
              className="text-gray-300 text-sm group-focus-within:text-gray-100 transition duration-300"
            >
              Jméno a příjmení
            </label>
            <div className="relative">
              <input
                type="text"
                className="border-2 border-gray-400 bg-gray-700 py-3 h-12 pl-14 pr-4 rounded-sm text-gray-100 w-full text-sm focus:outline-none focus:border-gray-100 focus:shadow-lg focus:shadow-white/10 transition duration-300"
                name="nameSurname"
                id="nameSurname"
                onChange={(e) => onNameChangeHandler(e.currentTarget.value)}
                value={name}
                aria-invalid={nameTouched && !nameValid}
              />
              <div
                className={classNames(
                  "absolute left-3 top-1/2 transform -translate-y-1/2 border-r-2  transition duration-300 pr-3",
                  nameTouched && !nameValid
                    ? "text-red-500 border-red-500"
                    : "border-gray-400 text-gray-300 group-focus-within:text-gray-100 group-focus-within:border-gray-100"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
            </div>
            {nameTouched && !nameValid && (
              <p role="alert" className="text-red-500 text-sm font-extralight">
                Jméno a příjmení musí být vyplněno
              </p>
            )}
          </div>
          <div className="flex flex-col gap-1 items-stretch group">
            <label
              htmlFor="tel"
              className="text-gray-300 text-sm group-focus-within:text-gray-100 transition duration-300"
            >
              Telefon
            </label>
            <div className="relative">
              <input
                type="tel"
                className="border-2 border-gray-400 bg-gray-700 py-3 h-12 pl-14 pr-4 rounded-sm text-gray-100 w-full text-sm  focus:outline-none focus:border-gray-100 focus:shadow-lg focus:shadow-white/10 transition duration-300"
                name="tel"
                id="tel"
                onChange={(e) => onPhoneChangeHandler(e.currentTarget.value)}
                value={phone}
                aria-invalid={phoneTouched && !phoneValid}
              />
              <div
                className={classNames(
                  "absolute left-3 top-1/2 transform -translate-y-1/2 border-r-2   transition duration-300 pr-3 border-gray-400 text-gray-300 group-focus-within:text-gray-100 group-focus-within:border-gray-100"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1 items-stretch group">
            <label
              htmlFor="house"
              className="text-gray-300 text-sm group-focus-within:text-gray-100 transition duration-300"
            >
              Vybraný dům
            </label>
            <div className="relative">
              <select
                className="border-2 border-gray-400 bg-gray-700 py-2 h-12 pl-14 pr-4 rounded-sm text-gray-100 w-full appearance-none text-sm  focus:outline-none focus:border-gray-100 focus:shadow-lg focus:shadow-white/10 transition duration-300"
                name="house"
                id="house"
                onChange={(e) => onHouseChangeHandler(e.currentTarget.value)}
                value={house}
                aria-invalid={houseTouched && !houseValid}
              >
                {items.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <div
                className={classNames(
                  "absolute left-3 top-1/2 transform -translate-y-1/2 border-r-2 transition duration-300 pr-3 border-gray-400 text-gray-300 group-focus-within:text-gray-100 group-focus-within:border-gray-100"
                )}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                  />
                </svg>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 absolute right-2 top-1/2 transform -translate-y-1/2 bg-gray-700 text-gray-300 group-focus-within:text-gray-100 transition duration-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
          <div className="sm:col-span-2 flex flex-col gap-1 items-stretch group">
            <label
              htmlFor="message"
              className="text-gray-300 text-sm group-focus-within:text-gray-100 transition duration-300"
            >
              Vaše zpráva
            </label>
            <div className="relative">
              <textarea
                className="border-2 border-gray-400 bg-gray-700 py-2 px-4 rounded-sm text-gray-100 w-full appearance-none focus:outline-none focus:border-gray-100 focus:shadow-lg focus:shadow-white/10 transition duration-300"
                rows={2}
                name="message"
                id="message"
                onChange={(e) => onMessageChangeHandler(e.currentTarget.value)}
                value={message}
                aria-invalid={messageTouched && !messageValid}
              />
            </div>
          </div>
          <div className="sm:col-span-2 flex justify-center">
            <button
              type="submit"
              disabled={isSending}
              className={classNames(
                "flex justify-center w-full sm:w-auto sm:inline-flex gap-4 px-6 py-3 lg:px-8 lg:py-4 text-sm lg:text-base items-center bg-gray-100 text-gray-900 hover:bg-white hover:text-black focus:outline-none focus:bg-white focus:text-black transition group"
              )}
            >
              <span className="font-medium tracking-wide">
                {isSending ? "Odesílám" : "Odeslat"}
              </span>
              {isSending ? (
                <svg
                  className="animate-spin h-5 w-5 lg:h-6 lg:w-6 text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="h-5 w-5 lg:h-6 lg:w-6 transition duration-300 group-hover:translate-x-2 group-focus:translate-x-2"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.28027 27.6491L30.5293 30.9411C30.8443 30.9841 31.1613 31.0001 31.4793 31.0001C34.7613 30.9971 37.6023 28.7251 38.3203 25.5231L43.5723 2.10205"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M17.619 38.375L2.29004 48.435"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M16.6664 50L8.27441 55.507"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M5.53172 33.3691L1.13672 36.2541"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M39.3369 45.935L42.6969 43.73"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M20.3223 58.4131L36.6703 47.6851"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path d="M51 18H49V20H51V18Z" fill="currentColor" />
                  <path d="M53 21H51V23H53V21Z" fill="currentColor" />
                  <path d="M55 24H53V26H55V24Z" fill="currentColor" />
                  <path
                    d="M16.822 43.145C16.822 43.145 20.284 48.214 21.433 49.907C21.884 50.571 22.637 51 23.5 51C24.018 51 24.499 50.843 24.898 50.573C24.971 50.523 57.819 27.627 57.898 27.573C58.559 27.119 59 26.363 59 25.5C59 24.982 58.837 24.492 58.567 24.093C58.431 23.893 43.709 2.301 43.573 2.102C43.124 1.437 42.363 1 41.5 1C40.982 1 40.501 1.157 40.102 1.427C39.715 1.696 7.45 24.184 7.102 24.427C6.437 24.876 6 25.637 6 26.5C6 27.018 6.157 27.499 6.427 27.898C6.437 27.912 12.44 36.717 12.45 36.732"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M11.6867 14.6548L9.2207 16.3738"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M27.6476 3.53076L14.5166 12.6828"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeMiterlimit="10"
                  />
                </svg>
              )}
            </button>
          </div>
          {result && (
            <>
              {isSuccess ? (
                <div className="sm:col-span-2 text-center text-green-500 text-sm sm:text-base flex flex-col gap-2 items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 22C6.47967 21.9939 2.00606 17.5203 2 12V11.8C2.10993 6.30453 6.63459 1.92796 12.1307 2.00088C17.6268 2.07381 22.0337 6.56889 21.9978 12.0653C21.9619 17.5618 17.4966 21.9989 12 22ZM7.41 11.59L6 13L10 17L18 9L16.59 7.58L10 14.17L7.41 11.59Z"
                      fill="currentColor"
                    ></path>
                  </svg>

                  <span>{result}</span>
                </div>
              ) : (
                <div className="sm:col-span-2 text-center text-red-500 text-sm sm:text-base flex flex-col gap-2 items-center">
                  <svg
                    className="h-5 w-5 sm:h-6 sm:w-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.2659 20.998H2.73288C2.37562 20.998 2.04551 20.8074 1.86688 20.498C1.68825 20.1886 1.68825 19.8074 1.86688 19.498L11.1329 3.49799C11.3117 3.1891 11.6415 2.9989 11.9984 2.9989C12.3553 2.9989 12.6851 3.1891 12.8639 3.49799L22.1299 19.498C22.3084 19.8072 22.3085 20.1882 22.1301 20.4975C21.9518 20.8069 21.622 20.9976 21.2649 20.998H21.2659ZM10.9999 15.998V17.998H11.9329H11.9979H12.0629H12.9979V15.998H10.9999ZM10.9999 8.99799V13.998H12.9999V8.99799H10.9999Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span>{result}</span>
                </div>
              )}
            </>
          )}
        </form>
      </div>
    </section>
  );
};
