import { graphql } from 'gatsby';
import React, { useState } from 'react';
import {
  CharacteristicSection,
  ContactSection,
  DocumentsSection,
  Footer,
  GallerySection,
  Helmet,
  HeroSection,
  IntroSection,
  InvestorSection,
  MapSection,
  Navbar,
  SelectionSection,
} from '../components/organisms';
import { HomePageQuery, OfferItem } from '../types';

const IndexPage = ({ data }: HomePageQuery) => {
  const content = data.file.childPagesYaml;
  const [house, setHouse] = useState<string>();

  function houseToString(house: OfferItem) {
    return `${house.title} | ${house.dispositions} m\u00B2 | ${house.houseArea} m\u00B2 | ${house.landArea} m\u00B2 | ${house.price} Kč`;
  }

  const houses = content.offers.items
    .filter((item) => item.status === 'K Dispozici')
    .map((item) => houseToString(item));

  return (
    <div className="overflow-hidden">
      <div id="portal-root">
        <div />
      </div>
      <Helmet {...content.seo} />
      <Navbar />
      <main className="bg-gray-50">
        <HeroSection {...content.hero} />
        <IntroSection {...content.intro} />
        <MapSection {...content.map} />
        <CharacteristicSection {...content.characteristic} />
        <InvestorSection {...content.investor} />
        <SelectionSection
          onOfferClick={(item) => {
            setHouse(houseToString(item));
            document
              ?.getElementById('house')
              ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            document.getElementById('house')?.focus({ preventScroll: true });
          }}
          {...content.offers}
        />
        <ContactSection
          items={houses}
          house={house ? house : houses.length > 0 ? houses[0] : ''}
          onHouseChange={(house) => setHouse(house)}
          {...content.contact}
        />
        <GallerySection {...content.gallery} />
        <DocumentsSection {...content.documents} />
      </main>
      <Footer />
    </div>
  );
};

export const query = graphql`
  {
    file(name: { eq: "home" }) {
      id
      childPagesYaml {
        seo {
          title
          description
          copyright
          image {
            childImageSharp {
              gatsbyImageData(formats: PNG, placeholder: BLURRED)
            }
          }
        }
        hero {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(formats: PNG, placeholder: BLURRED)
            }
          }
        }
        intro {
          title
          description
          features {
            title
            icon
          }
        }
        map {
          title
          src
        }
        characteristic {
          title
          description
          items {
            title
            description
            images {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(formats: PNG, placeholder: BLURRED, width: 900)
                }
              }
            }
          }
        }
        investor {
          title
          description
          name
          adress
          ico
          person
          tel
          mail
          image {
            childImageSharp {
              gatsbyImageData(formats: PNG, placeholder: BLURRED, width: 800)
            }
          }
        }
        offers {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(formats: PNG, placeholder: BLURRED)
            }
          }
          items {
            id
            title
            dispositions
            houseArea
            landArea
            price
            status
            left
            top
          }
        }
        contact {
          title
          description
          person
          tel
          mail
          adress
          mapLink
        }
        gallery {
          title
          description
          galleries {
            title
            thumbnail {
              childImageSharp {
                gatsbyImageData(formats: PNG, placeholder: BLURRED, width: 800)
              }
            }
            images {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(formats: PNG, placeholder: BLURRED, width: 900)
                }
              }
            }
          }
        }
        documents {
          title
          description
          items {
            title
            url
          }
        }
      }
    }
  }
`;

export default IndexPage;
//
