import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Image } from "../../types";
import { classNames } from "../../utils";

type Props = {
  title: string;
  description: string;
  image: Image;
};

export const HeroSection = ({ title, description, image }: Props) => {
  const [appear, setAppear] = useState(false);

  useEffect(() => {
    setAppear(true);
  });

  return (
    <section className="h-screen flex items-center justify-center bg-black relative z-20 overflow-hidden">
      <div className="absolute inset-0">
        <Fade triggerOnce delay={100} duration={1000} className="h-full w-full">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt=""
            className="h-full w-full object-cover"
          />
        </Fade>
        <Fade
          triggerOnce
          delay={1000}
          duration={1500}
          className="absolute inset-0"
        >
          <div className="absolute inset-0 bg-black bg-opacity-40 mix-blend-multiply"></div>
        </Fade>
      </div>
      <div className="container px-4">
        <div className="relative max-w-6xl py-16 sm:py-24 lg:py-72 mx-auto">
          <Fade triggerOnce delay={1100} duration={1000}>
            <div
              className={classNames(
                "py-12 px-4 sm:px-6 lg:px-8 bg-black bg-opacity-75 h-fit w-fit"
              )}
            >
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold tracking-tight font-display text-white">
                {title}
              </h1>
              <p className="mt-6 max-w-2xl md:text-lg xl:text-xl text-gray-200 leading-relaxed md:leading-relaxed xl:leading-relaxed mb-6 whitespace-pre-wrap">
                {description}
              </p>
              <div className="flex gap-3">
                <Link
                  to="/#uvod"
                  className="inline-flex gap-4 px-6 py-3 lg:px-8 lg:py-4 text-sm lg:text-base items-center border-2 border-white bg-transparent text-white hover:bg-white focus:bg-white hover:text-black focus:text-black focus:outline-none transition duration-300 shadow-xl transition group"
                >
                  <span className="font-medium tracking-wide">
                    Více informací
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 lg:h-6 lg:w-6 group-hover:translate-x-2 group-focus:translate-x-2 transition duration-300"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M17 8l4 4m0 0l-4 4m4-4H3"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};
