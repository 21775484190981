import { Link } from "gatsby";
import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Icon, IconName } from "../atoms";

type Props = {
  title: string;
  description: string;
  features: {
    title: string;
    icon: IconName;
  }[];
};

export const IntroSection = ({ title, description, features }: Props) => (
  <section className="py-24 relative" id="uvod">
    <svg
      className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
      width={404}
      height={784}
      fill="none"
      viewBox="0 0 404 784"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
          x={0}
          y={0}
          width={20}
          height={20}
          patternUnits="userSpaceOnUse"
        >
          <rect
            x={0}
            y={0}
            width={4}
            height={4}
            className="text-gray-200"
            fill="currentColor"
          />
        </pattern>
      </defs>
      <rect
        width={202}
        height={784}
        fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
      />
    </svg>
    <svg
      className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-1/4"
      width={404}
      height={784}
      fill="none"
      viewBox="0 0 404 784"
      aria-hidden="true"
    >
      <defs>
        <pattern
          id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e8"
          x={0}
          y={0}
          width={20}
          height={20}
          patternUnits="userSpaceOnUse"
        >
          <rect
            x={0}
            y={0}
            width={4}
            height={4}
            className="text-gray-100"
            fill="currentColor"
          />
        </pattern>
      </defs>
      <rect
        width={404}
        height={784}
        fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e8)"
      />
    </svg>
    <div className="container px-4 mx-auto relative z-10">
      <div className="grid xl:grid-cols-2 gap-16">
        <div>
          <h2 className="text-2xl md:text-4xl lg:text-5xl mb-1 md:mb-2 font-semibold font-display">
            {title}
          </h2>
          <hr className="w-32 border-black mb-4" />
          {description.split("\n").map((paragraph) => (
            <p
              key={paragraph}
              className="leading-relaxed text-gray-600 text-lg font-extralight mb-3 text-sm lg:text-base lg:leading-relaxed"
            >
              {paragraph}
            </p>
          ))}
          <div className="flex flex-wrap gap-3 mt-6">
            <Link
              to="/#vyber-domu"
              className="inline-flex gap-4 px-6 py-3 lg:px-8 lg:py-4 text-sm lg:text-base items-center border-2 border-black bg-black text-white shadow transition duration-300 hover:shadow-xl focus:shadow-xl focus:outline-none group"
            >
              <span className="font-medium tracking-wide">Výběr domu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 lg:h-6 lg:w-6 group-hover:translate-x-2 group-focus:translate-x-2 transition duration-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </Link>
            <Link
              to="/#charakteristika"
              className="inline-flex gap-4 px-6 py-3 lg:px-8 lg:py-4 text-sm lg:text-base items-center border-2 border-black hover:bg-black hover:text-white transition duration-300 focus:outline-none focus:bg-black focus:text-white group"
            >
              <span className="font-medium tracking-wide">
                Charakteristika domů
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 lg:h-6 lg:w-6 group-hover:translate-x-2 group-focus:translate-x-2 transition duration-300"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17 8l4 4m0 0l-4 4m4-4H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div className="grid md:grid-cols-2 gap-8 xl:mt-16">
          {features.map((feature, i) => (
            <Slide
              key={feature.title}
              direction="right"
              triggerOnce
              delay={125 * (i + 1)}
            >
              <Fade triggerOnce delay={125 * (i + 1)}>
                <div>
                  <div className="flex justify-center">
                    <div className="p-4 bg-black text-white rounded-lg shadow-lg">
                      <Icon
                        className="h-12 w-12"
                        iconName={feature.icon}
                      ></Icon>
                    </div>
                  </div>
                  <p className="text-center mt-4 max-w-sm mx-auto font-display font-medium  p-4 pt-10 bg-gray-100 -mt-6 rounded-lg shadow">
                    {feature.title}
                  </p>
                </div>
              </Fade>
            </Slide>
          ))}
        </div>
      </div>
    </div>
  </section>
);
