import { Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { Bounce, Fade, Slide } from "react-awesome-reveal";
import { FadeCarousel } from "../atoms";
import { classNames } from "../../utils";
import { Image } from "../../types";
import { GatsbyImage } from "gatsby-plugin-image";

type Props = {
  title: string;
  description: string;
  items: {
    title: string;
    description: string;
    images: {
      alt: string;
      image: Image;
    }[];
  }[];
};

export const CharacteristicSection = ({ title, description, items }: Props) => {
  return (
    <section className="max-w-7xl px-4 mx-auto py-16" id="charakteristika">
      <header className="text-center mb-24 max-w-5xl mx-auto">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-1 md:mb-2 font-semibold font-display">
          {title}
        </h2>
        <hr className="w-32 border-black mb-4 mx-auto" />
        {description?.split("\n").map((paragraph) => (
          <p
            key={paragraph}
            className="font-light lg:text-lg text-gray-600 mb-2 max-w-5xl mx-auto"
          >
            {paragraph}
          </p>
        ))}
      </header>
      <div className="space-y-24 lg:space-y-36">
        {items.map((section, i) => (
          <div
            key={section.title}
            className={classNames(
              "grid lg:grid-cols-3 items-center gap-12",
              i % 2 === 0 ? "lg:-ml-24" : "lg:-mr-24"
            )}
          >
            <Fade
              delay={100}
              className={classNames(
                "relative aspect-[5/3] lg:col-span-2 h-full w-full",
                i % 2 === 0 ? "lg:order-1" : "lg:order-2"
              )}
              triggerOnce
            >
              <FadeCarousel
                className="h-full w-full"
                items={section.images.map((image) => ({
                  ...image,
                  id: image.alt,
                }))}
                itemRenderer={(image) => (
                  <div className="relative h-full w-full">
                    <GatsbyImage
                      image={image.image.childImageSharp.gatsbyImageData}
                      className={classNames(
                        "h-full w-full object-cover object-center shadow-lg"
                      )}
                      alt={image.alt}
                    />
                    <p className="text-center mt-4 text-xs text-gray-500 uppercase font-light tracking-wide">
                      {image.alt}
                    </p>
                  </div>
                )}
              />
            </Fade>
            <Slide
              delay={250}
              direction="up"
              className={classNames(i % 2 === 0 ? "lg:order-2" : "lg:order-1")}
              triggerOnce
            >
              <Fade delay={250} triggerOnce>
                <h3 className="text-lg md:text-xl lg:text-2xl mb-1 md:mb-2 font-semibold font-display tracking-wide lg:tracking-widest">
                  {section.title}
                </h3>
                <hr className="w-32 border-gray-600 mb-2" />
                <p className="mb-4 leading-loose text-gray-700 text-sm lg:text-base lg:leading-loose">
                  {section.description}
                </p>
              </Fade>
            </Slide>
          </div>
        ))}
      </div>
    </section>
  );
};
