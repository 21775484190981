import React from "react";

export type IconName = "mountains" | "nature" | "walk" | "house";

type Props = {
  iconName: IconName;
  className?: string;
};

export function Icon({ iconName, className }: Props): JSX.Element {
  const icon = iconLookup[iconName];

  return (
    <>
      {React.cloneElement(icon, {
        className: `block ${className}`,
      })}
    </>
  );
}

const iconLookup: { [name in IconName]: JSX.Element } = {
  mountains: (
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29 8H27V10H29V8Z" fill="currentColor" />
      <path d="M27 13H25V15H27V13Z" fill="currentColor" />
      <path d="M22 1H20V3H22V1Z" fill="currentColor" />
      <path d="M15 8H13V10H15V8Z" fill="currentColor" />
      <path d="M17 3H15V5H17V3Z" fill="currentColor" />
      <path d="M27 3H25V5H27V3Z" fill="currentColor" />
      <path d="M17 13H15V15H17V13Z" fill="currentColor" />
      <path d="M22 15H20V17H22V15Z" fill="currentColor" />
      <path
        d="M21 12C22.6569 12 24 10.6569 24 9C24 7.34315 22.6569 6 21 6C19.3431 6 18 7.34315 18 9C18 10.6569 19.3431 12 21 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M56.745 33.665L39.222 10.608C38.932 10.227 38.478 10.001 38 10C37.519 9.999 37.069 10.225 36.778 10.608L19.311 33.591"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M25.6481 33.4629L24.6731 34.7459"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M30.434 27.166L27.708 30.752"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M51.4981 20.8135L47.4121 15.4355"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M54.4761 24.7323L53.3721 23.2783"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28.084 22.0476L35 25.9996L39 22.9996L43 24.9996L47.068 20.9316"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.458 30.765L14.273 21.62C13.967 21.231 13.496 21.001 13 21C12.502 20.999 12.035 21.229 11.727 21.62L2.32202 33.59"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M7.68506 26.7644L12.0001 30.0004L17.4391 25.6494"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M11 38H57"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9 44V50"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M53 49.851C53 49.624 52.934 49.401 52.808 49.212L49.035 43.552C48.804 43.206 48.417 42.999 48 43C47.585 43.001 47.195 43.207 46.965 43.553L43.194 49.209C43.068 49.399 43 49.636 43 49.864C43.001 50.5 43.517 51 44.153 51H51.852C52.487 51 53 50.486 53 49.851V49.851Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14 42.851C14 42.624 13.934 42.401 13.808 42.212L10.035 36.552C9.804 36.206 9.417 35.999 9 36C8.585 36.001 8.195 36.207 7.965 36.553L4.194 42.209C4.068 42.399 4 42.636 4 42.864C4.001 43.5 4.517 44 5.153 44H12.852C13.487 44 14 43.486 14 42.851V42.851Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48 51V57"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.375 57.64L30.874 53.267C32.736 52.249 34 50.272 34 48C34 45.504 32.475 43.365 30.306 42.461L21 38"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8.35693 56.654C10.7029 54.454 13.6939 53.078 16.8899 52.639C20.5759 52.132 24.9999 50.917 24.9999 48C24.9999 44 12.9999 38 12.9999 38"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  nature: (
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29 15H31"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M25 15L19.184 24.97C19.064 25.177 19 25.411 19 25.65C19 26.395 19.604 27 20.35 27H23L16.275 36.607C16.096 36.863 16 37.167 16 37.479C16 38.319 16.681 39 17.521 39H19"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M32 27H39.65C40.395 27 41 26.396 41 25.65C41 25.411 40.937 25.177 40.816 24.97L35 15H36.874C37.496 15 38 14.496 38 13.874C38 13.627 37.919 13.388 37.77 13.192L31.225 4.607C30.934 4.225 30.48 4 30 4C29.519 4 29.067 4.224 28.775 4.607L22.23 13.192C22.08 13.388 22 13.628 22 13.874C22 14.496 22.504 15 23.126 15H27"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41.001 39H42.48C43.319 39 44 38.319 44 37.479C44 37.167 43.904 36.863 43.725 36.607L37 27"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M40 43L30 32L20 43"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27 56V48C27 47.448 27.448 47 28 47H32C32.552 47 33 47.448 33 48V56"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M38 40.7998V55.9998"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22 41H38"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22 55.9998V40.7998"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M57 56H59"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14 44V56"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M5 56H55"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M45 22H43"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M52 56V44"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M46 44V56"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M52 51H46"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M43.2051 17.592L46.8381 12.596C47.1111 12.222 47.5361 11.999 48.0001 12C48.4581 12.001 48.8991 12.22 49.1711 12.589L54.7811 20.203C54.9221 20.396 55.0001 20.629 55.0001 20.87C55.0011 21.494 54.4961 22 53.8721 22H47.0001"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M52 32L58.806 42.203C58.932 42.393 59 42.616 59 42.844C59 43.483 58.482 44 57.844 44H42"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M50.998 22L55.837 30.216C55.944 30.398 56.001 30.605 56.001 30.816C56 31.47 55.47 32 54.816 32H45"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M15 22H16.999"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8 56V44"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8 51H14"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M16.763 17.549L13.161 12.597C12.889 12.222 12.463 11.999 12 12C11.542 12.001 11.101 12.22 10.829 12.589L5.21901 20.203C5.07701 20.396 5.00001 20.629 5.00001 20.87C4.99801 21.494 5.50401 22 6.12801 22H13"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8 32L1.194 42.203C1.068 42.393 1 42.616 1 42.844C1 43.483 1.517 44 2.156 44H18"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M9.002 22L4.163 30.216C4.057 30.398 4 30.605 4 30.817C4 31.47 4.53 32 5.183 32H15"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  walk: (
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M34 8C34 10.209 32.209 12 30 12C27.791 12 26 10.209 26 8V6C26 3.791 27.791 2 30 2C32.209 2 34 3.791 34 6V8Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M30.459 21.336L34.277 28C34.623 28.595 35.261 29 36 29H43C44.104 29 45 28.104 45 27C45 25.895 44.104 25 43 25H37.161L33.514 18.619C32.588 16.999 30.864 15.999 28.998 16C27.34 16.001 26 17.342 26 19V34C26 35.657 26.781 37.234 28 38V55C28 56.105 28.895 57 30 57H35C35.544 57 35.997 56.561 36 56.016C36.007 54.815 35.241 53.746 34.103 53.366L33 52.999V31"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M28 43.458L18.13 49.26C17.436 49.595 17.023 50.279 17.001 51C16.991 51.312 17.055 51.631 17.2 51.931L19.342 56.433C19.513 56.788 19.872 57 20.243 57C20.998 57.001 22.005 56.079 22 54.638C21.999 54.287 21.9 53.38 21.851 53L28 49"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8 57H55"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41.656 29L47.281 57"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48 37H50"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M52 37H58"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8 42H10"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M12 42H19"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3 27H5"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M7 27H13"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M6 9H8"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M10 9H17"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41 15H43"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M45 15H54"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M20 29H26"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M23 22C21.343 22 20 20.657 20 19C20 17.343 21.343 16 23 16C24.657 16 26 17.343 26 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M26 32C26 33.657 24.657 35 23 35C21.343 35 20 33.657 20 32V25C20 23.343 21.343 22 23 22H26"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  house: (
    <svg viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 37.9999V52.0839C5 52.6159 5.377 53.0729 5.899 53.1729L25.504 56.9529C25.667 56.9889 25.833 56.9989 25.999 56.9989C26.225 56.9989 26.439 56.9629 26.653 56.9169L53.215 50.1959C53.676 50.0809 54 49.6659 54 49.1909V34.3999"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M33.997 14.9258L15.665 16.8108C15.243 16.8538 14.861 17.0798 14.619 17.4278L1.52002 36.6998"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M14.824 17.191L28.078 37.582C28.653 38.466 29.636 39 30.691 39C30.896 39 31.101 38.98 31.302 38.939L54.593 34.281C55.412 34.118 56 33.4 56 32.566C56 32.198 55.884 31.839 55.668 31.541L43.321 14.46C43.123 14.17 42.795 14 42.449 14C42.413 14 42.377 14.002 42.341 14.006L40 14.314"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M26 41V57"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M10 53.964V40.883C10 39.843 10.843 39 11.883 39C11.961 39 12.039 39.005 12.117 39.015L17.823 39.728C19.067 39.883 20 40.941 20 42.194V55.892"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48 39V46"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41 41V48"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M33 43V50"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M56 12H54V14H56V12Z" fill="currentColor" />
      <path d="M54 17H52V19H54V17Z" fill="currentColor" />
      <path d="M49 5H47V7H49V5Z" fill="currentColor" />
      <path d="M44 7H42V9H44V7Z" fill="currentColor" />
      <path d="M54 7H52V9H54V7Z" fill="currentColor" />
      <path
        d="M34 21V12.019C34 11.456 34.456 11 35.019 11H38.981C39.544 11 40 11.456 40 12.019V24"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path d="M59 50H57V52H59V50Z" fill="currentColor" />
      <path d="M57 55H55V57H57V55Z" fill="currentColor" />
      <path d="M11 11H9V13H11V11Z" fill="currentColor" />
      <path d="M4 18H2V20H4V18Z" fill="currentColor" />
      <path d="M6 13H4V15H6V13Z" fill="currentColor" />
      <path d="M6 23H4V25H6V23Z" fill="currentColor" />
      <path d="M47 55H45V57H47V55Z" fill="currentColor" />
      <path d="M39 32H37V34H39V32Z" fill="currentColor" />
      <path d="M44 31H42V33H44V31Z" fill="currentColor" />
      <path d="M49 30H47V32H49V30Z" fill="currentColor" />
      <path d="M52 57H50V59H52V57Z" fill="currentColor" />
      <path
        d="M31.706 8.379C30.531 9.516 27.125 12 21 12C11.47 12 9.38696 5.372 9.38696 5.372C9.38696 5.372 11.257 6.001 13.422 6C13.941 6 14.49 5.963 15.002 5.861C20.886 4.689 22.016 1 27.132 1C31.122 1 35.124 3.068 37.365 7.701"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  ),
};
