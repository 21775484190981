import React from "react";

type Props = {
  className?: string;
};

export const Logo = ({ className }: Props) => (
  <svg
    className={className}
    version="1.1"
    viewBox="0 0 60.664 53.721"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(-126.68 -47.29)">
      <g transform="matrix(.26458 0 0 .26458 8.5308 -17.311)">
        <g transform="translate(503.68 447.2)">
          <path
            d="m0 0-15.52-25.76h-3.84v25.76h-17.28v-68.16h29.44c5.6 0 10.4 0.8 14.24 2.72 4 1.92 6.88 4.48 8.8 7.68 1.92 3.2 3.04 7.04 3.04 11.2 0 4.96-1.44 9.12-4.32 12.64-2.88 3.36-7.04 5.76-12.48 7.04l16.96 26.88zm-19.36-37.12h10.56c3.52 0 6.08-0.64 7.68-2.08 1.6-1.44 2.4-3.68 2.4-6.4 0-2.72-0.8-4.8-2.56-6.4-1.6-1.6-4.16-2.4-7.52-2.4h-10.56z"
            fill="currentColor"
          />
        </g>
        <g transform="translate(636.48 447.2)">
          <path
            d="m0 0-15.52-25.76h-3.84v25.76h-17.28v-68.16h29.44c5.6 0 10.4 0.8 14.4 2.72 3.84 1.92 6.72 4.48 8.64 7.68 2.08 3.2 3.04 7.04 3.04 11.2 0 4.96-1.44 9.12-4.32 12.64-2.88 3.36-7.04 5.76-12.48 7.04l16.96 26.88zm-19.36-37.12h10.72c3.36 0 5.92-0.64 7.52-2.08 1.6-1.44 2.4-3.68 2.4-6.4 0-2.72-0.8-4.8-2.4-6.4-1.76-1.6-4.16-2.4-7.52-2.4h-10.72z"
            fill="currentColor"
          />
        </g>
        <g transform="translate(551.52 423.2)">
          <path
            d="m0 0v24h-17.28v-68.16h28c8.48 0 14.88 1.92 19.36 5.92 4.48 4 6.72 9.44 6.72 16.32 0 4.16-1.12 8-3.04 11.36-1.92 3.2-4.96 5.92-8.8 7.84-4 1.76-8.64 2.72-14.24 2.72zm19.2-21.92c0-5.92-3.52-8.96-10.4-8.96h-8.8v17.76h8.8c6.88 0 10.4-2.88 10.4-8.8z"
            fill="#b19c87"
          />
        </g>
        <g transform="translate(640.16 360.32)">
          <path
            d="m0 0v-26.88h-27.68v-20.8l-39.68-23.52-17.12 11.36v-36.48l-71.52-19.84v116.16h-37.6v5.12h42.88v-114.56l60.96 16.96v36.16l-16.48 11.2v50.24h21.76v-58.72l17.28-11.52 34.24 20.32v17.92h-14.88v32h20.16v-26.88h22.4v26.88h40.96v-5.12zm-89.76 0h-11.2v-42.56l11.2-7.52zm56.8 0h-9.6v-21.76h9.6z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);
