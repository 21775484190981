import React from 'react';

type Props = {
  className?: string;
};

export function PNLogo({ className }: Props) {
  return (
    <svg className={className} version="1.1" viewBox="0 0 103.92 113.29">
      <defs>
        <linearGradient
          id="SVGID_1_"
          x1="167.16"
          x2="167.16"
          y1="205.12"
          y2="134.13"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0098D8" offset="0" />
          <stop stopColor="#0061A1" offset="1" />
        </linearGradient>
        <linearGradient
          id="SVGID_2_"
          x1="175.07"
          x2="237.46"
          y1="116.24"
          y2="196.09"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0098D8" offset="0" />
          <stop stopColor="#0061A1" offset="1" />
        </linearGradient>
        <linearGradient
          id="SVGID_3_"
          x1="185.31"
          x2="219.39"
          y1="225.39"
          y2="166.36"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0098D8" offset="0" />
          <stop stopColor="#0061A1" offset="1" />
        </linearGradient>
        <linearGradient
          id="SVGID_4_"
          x1="157.76"
          x2="157.76"
          y1="204.47"
          y2="204.47"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F18920" offset=".0986" />
          <stop stopColor="#E62551" offset=".8493" />
        </linearGradient>
        <linearGradient
          id="SVGID_5_"
          x1="181.7"
          x2="181.7"
          y1="188.27"
          y2="188.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F18920" offset=".0986" />
          <stop stopColor="#E62551" offset=".8493" />
        </linearGradient>
        <linearGradient
          id="SVGID_6_"
          x1="186.81"
          x2="186.81"
          y1="193.38"
          y2="193.38"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F18920" offset=".0986" />
          <stop stopColor="#E62551" offset=".8493" />
        </linearGradient>
      </defs>

      <g transform="translate(-152.17 -118.88)">
        <path
          className="st0"
          d="m182.14 186.94c0.01 0.65-0.31 1.26-0.85 1.62l-23.53 15.91c-2.35 1.65-5.59-0.03-5.59-2.9v-67.43l29.61 20.07z"
          fill="url(#SVGID_1_)"
        />
        <path
          className="st1"
          d="m251.56 185.06-11.98 8.33-87.41-59.25 16.85-12.76c4.17-3.16 9.88-3.34 14.24-0.46l68.24 46.77c6.09 4.17 6.12 13.15 0.06 17.37z"
          fill="url(#SVGID_2_)"
        />
        <path
          className="st2"
          d="m171.98 230.4-15.18-9.62c-2.54-1.61-2.6-5.3-0.11-6.99l56.51-38.3 26.38 17.88-55.17 36.87c-3.75 2.52-8.62 2.58-12.43 0.16z"
          fill="url(#SVGID_3_)"
        />
        <path className="st3" d="m157.76 204.47" fill="url(#SVGID_4_)" />
        <path className="st4" d="m181.7 188.27" fill="url(#SVGID_5_)" />
        <path className="st5" d="m186.81 193.38" fill="url(#SVGID_6_)" />
      </g>
    </svg>
  );
}
