import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Fade } from 'react-awesome-reveal';
import { Image } from '../../types';

type Props = {
  title: string;
  description: string;
  image: Image;
  name: string;
  adress: string;
  ico: string;
  person: string;
  tel: string;
  mail: string;
};

export const InvestorSection = ({
  title,
  description,
  image,
  name,
  adress,
  ico,
  tel,
  person,
  mail,
}: Props) => (
  <section className="container px-4 mx-auto py-24" id="investor">
    <div className="bg-gray-200 grid xl:grid-cols-5 gap-8 shadow-sm">
      <div className="xl:col-span-3 p-8 sm:p-10 md:p-12 lg:p-24">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl mb-6 font-semibold font-display">
          {title}
        </h2>
        {description.split('\n').map((paragraph) => (
          <p key={paragraph} className="leading-loose text-gray-700 mb-6 text-sm md:text-base">
            {paragraph}
          </p>
        ))}
        <address className="not-italic ">
          <h3 className="text-xl font-medium mb-1">{name}</h3>
          <div className="h-0.5 w-48 bg-gray-400 mb-3"> </div>
          <div className="grid sm:grid-cols-2 gap-8">
            <div className="">
              <p className="text-gray-700 mb-1">{adress} </p>
              <p className="text-gray-700 mb-1">IČO: {ico} </p>
              <p className="text-gray-700">Jednatel: {person}</p>
            </div>
            <div>
              <a
                href={`tel:${tel}`}
                className="flex gap-2 items-center text-gray-700 hover:text-black transition duration-300 mb-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 3l-6 6m0 0V4m0 5h5M5 3a2 2 0 00-2 2v1c0 8.284 6.716 15 15 15h1a2 2 0 002-2v-3.28a1 1 0 00-.684-.948l-4.493-1.498a1 1 0 00-1.21.502l-1.13 2.257a11.042 11.042 0 01-5.516-5.517l2.257-1.128a1 1 0 00.502-1.21L9.228 3.683A1 1 0 008.279 3H5z"
                  />
                </svg>
                <span>{tel}</span>
              </a>

              <a
                href={`mailto:${mail}`}
                className="flex gap-2 items-center text-gray-700 hover:text-black transition duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                  />
                </svg>
                <span>{mail}</span>
              </a>
            </div>
          </div>
        </address>
      </div>
      <div className="relative xl:col-span-2">
        <svg
          className="absolute z-20 left-0 top-0 h-full text-gray-200 hidden xl:block"
          viewBox="0 0 50 21"
        >
          <polygon points="0,0 5,0 0,21" fill="currentColor" />
        </svg>
        <Fade delay={100} className="w-full h-full" triggerOnce>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt=""
            className="w-full h-full object-cover relative z-10 max-h-56 sm:max-h-64 md:max-h-72 lg:max-h-96 xl:max-h-max"
          />
        </Fade>
      </div>
    </div>
  </section>
);
