import ReactHelmet from "react-helmet";
import React from "react";
import { Image } from "../../types";
import { getImage } from "gatsby-plugin-image";

type Props = {
  title: string;
  description: string;
  copyright: string;
  image: Image;
};

export const Helmet = ({ title, description, copyright, image }: Props) => {
  const url = "https://jizerskeroubenky.cz";
  const imageUrl = url + getImage(image.childImageSharp)?.images.fallback?.src;

  return (
    <ReactHelmet>
      <html lang="cs" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta charSet="utf-8" />

      <title>{title}</title>
      <link rel="canonical" href={"https://jizerskeroubenky.cz"} />

      <meta name="copyright" content={copyright} />
      <meta name="robots" content="index, follow" />

      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="author" content="Prezentace Nemovitostí" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={"https://jizerskeroubenky.cz"} />
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={"https://jizerskeroubenky.cz"} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </ReactHelmet>
  );
};
